import { useEffect, useState } from "react"

type Devices = "isPhone" | "isTablet" | "isLaptop" | "isDesktop"

type UseMediaQueryType = () => Record<Devices, boolean>

const mediaPoints: Record<string, string> = {
    phone: "(max-width: 425px)",
    tablet: "(max-width: 768px)",
    laptop: "(max-width: 1024px)",
    desktop: "(min-width: 1025px)",
}

const useMediaQuery: UseMediaQueryType = () => {
    const [state, setState] = useState<Record<Devices, boolean>>({
        isPhone: false,
        isTablet: false,
        isLaptop: false,
        isDesktop: false,
    })

    useEffect(() => {
        const mediaQueryList = Object.keys(mediaPoints).map((key) =>
            window.matchMedia(mediaPoints[key])
        )

        const changeMediaHandler = (): void => {
            const newState: Record<Devices, boolean> = {
                isPhone: mediaQueryList[0].matches,
                isTablet: mediaQueryList[1].matches || mediaQueryList[0].matches,
                isLaptop: mediaQueryList[2].matches || mediaQueryList[1].matches || mediaQueryList[0].matches,
                isDesktop: mediaQueryList[3].matches || mediaQueryList[2].matches || mediaQueryList[1].matches || mediaQueryList[0].matches,
            }

            setState(newState)
        }

        mediaQueryList.forEach((mediaQuery) => {
            mediaQuery.addEventListener("change", changeMediaHandler)
        })

        // Initial check for the current media query
        const initialState: Record<Devices, boolean> = {
            isPhone: mediaQueryList[0].matches,
            isTablet: mediaQueryList[1].matches || mediaQueryList[0].matches,
            isLaptop: mediaQueryList[2].matches || mediaQueryList[1].matches || mediaQueryList[0].matches,
            isDesktop: mediaQueryList[3].matches || mediaQueryList[2].matches || mediaQueryList[1].matches || mediaQueryList[0].matches,
        }

        setState(initialState)

        return () => {
            mediaQueryList.forEach((mediaQuery) => {
                mediaQuery.removeEventListener("change", changeMediaHandler)
            })
        }
    }, [])

    return state
}

export default useMediaQuery
