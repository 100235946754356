import { type FC } from "react"
import { type IRedirect } from "./interface"
import { useNavigate } from "react-router-dom"
import "./index.scss"
import ArrowForwardIcon from "../ArrowForwardIcon"

const HeaderRedirect: FC<{ header?: string }> = ({ header, }) => {
    return (
        <>
            {
                header &&
                <div className="redirect__header">
                    <p className="redirect__title">
                        {header} <ArrowForwardIcon />
                    </p>
                </div>
            }
        </>
    )
}
//! TODO изменить порядо тем, от простой к сложной
const Redirect: FC<IRedirect> = (props) => {
    const { children, header, theme, to, callback, ...othersProps } = props
    const navigate = useNavigate()

    const currentClasses = ["redirect"]

    if (theme) {
        currentClasses.push("redirect_theme_".concat(theme))
    }

    const handleRedirectClick = (): void => {
        callback?.()
        navigate(to)
    }

    return (
        <div
            className={currentClasses.join(" ")}
            onClick={handleRedirectClick}
            {...othersProps}
        >
            <HeaderRedirect header={header}/>
            {children}
        </div>
    )
}

export default Redirect
