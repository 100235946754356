import { type FC, useRef } from "react"
import "./index.scss"
import { type ICarousel } from "./interface"
import { ArrowForwardIcon, Button } from "presentation/components/index"

const Carousel: FC<ICarousel> = (props) => {
    const CarouselRef = useRef<HTMLDivElement | null>(null)
    const handleScrollLeft = (number: number): void => {
        if (CarouselRef.current?.scrollLeft !== undefined) {
            CarouselRef.current.scrollLeft -= CarouselRef.current?.clientWidth

        }
    }
    const handleScrollRight = (number: number): void => {
        if (CarouselRef.current?.scrollLeft !== undefined) {
            CarouselRef.current.scrollLeft += CarouselRef.current?.clientWidth
        }
    }

    return (
        <div className="carousel">
            <Button
                onClick={() => handleScrollLeft(50)}
                rigthIcon={<ArrowForwardIcon/>}
                theme={"outline"}
            />
            <section className="carousel__wrapper" {...props} ref={CarouselRef} />
            <Button
                onClick={() => handleScrollRight(50)}
                rigthIcon={<ArrowForwardIcon/>}
                className="login-form__button"
                theme={"outline"}
            />
        </div>
    )
}

export default Carousel
