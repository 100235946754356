import { useState, type FC } from "react"
import { Card } from "../../../../components"
import { useGetProfileDataQuery } from "presentation/store/services/profileApi"
import { ReactComponent as ArrowSvg } from "presentation/assets/svg/arrow.svg"
import "./index.scss"
import dayjs from "dayjs"

const ProfileCourseList: FC = () => {
    const [shownMore, setShownMore] = useState(false)
    const maxShownCourses = 3

    const { data, } = useGetProfileDataQuery("")

    const volunteerCourses = shownMore
        ? data?.courses
        : data?.courses.slice(0, maxShownCourses)
    const courseCount = data?.courses.length || 0

    return (
        <Card className="profile-course-list">
            <p className="profile-course-list__header">Пройденные мной курсы</p>
            {
                volunteerCourses?.map((course, index) => (
                    <CourseListItem
                        key={`volunteer-course-${index}`}
                        name={course.name}
                        date={course.certificateDate}
                    />
                ))
            }
            {
                courseCount > maxShownCourses &&
                (
                    <button
                        className={"profile-course-list__show-more-button"}
                        data-shown={shownMore}
                        onClick={() => setShownMore(!shownMore)}
                    >
                        {shownMore ? "Скрыть" : "Показать ещё"}
                        <span><ArrowSvg fill="black" /></span>
                    </button>
                )
            }
        </Card>
    )
}

interface ItemProps {
    name: string
    date: string
}

const CourseListItem: FC<ItemProps> = ({ name, date, }) => {
    return (
        <div className="profile-course-list__item">
            <p className="profile-course-list__title">{name}</p>
            <p className="profile-course-list__date">
                {dayjs(date).format("DD.MM.YYYY HH:mm")}
            </p>
        </div>

    )
}

// 27.04.2023  11:11
export default ProfileCourseList
