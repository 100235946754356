import { type FC } from "react"
import "./index.scss"
import { Header } from "../../components"
import { type IBaseLayout } from "./intreface"

const BaseLayout: FC<IBaseLayout> = (props) => {
    const { children, ...othersProps } = props

    return (
        <>
            <Header />
            <main className="base-layout" {...othersProps}>
                <section className="base-layout__content">
                    {children}
                </section>
            </main>
        </>
    )
}

export default BaseLayout
