import { type FC } from "react"
import "./index.scss"
import { NavLink } from "react-router-dom"
import { RouteEnum } from "../../../main/routes/mainRoute"

const PrivacyMenu: FC = () => {
    return (
        <nav className="privacy-menu">
            <input id="privacy-menu-dropdown" className="privacy-menu__checkbox" type="checkbox" />
            <label htmlFor="privacy-menu-dropdown" className="privacy-menu__label">
                Все документы
            </label>
            <ul className="privacy-menu__list">
                <li>
                    <NavLink
                        end
                        to={`/${RouteEnum.PRIVACY}`}
                        className={({ isActive, }) =>
                            !isActive
                                ? "privacy-menu__link"
                                : "privacy-menu__link privacy-menu__link_active"
                        }
                    >
                        Политика обработки персональных данных
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/${RouteEnum.PRIVACY}/${RouteEnum.PERSONAL_DATA}`}
                        className={({ isActive, }) =>
                            !isActive
                                ? "privacy-menu__link"
                                : "privacy-menu__link privacy-menu__link_active"
                        }
                    >
                        Согласие на обработку персональных данных
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/${RouteEnum.PRIVACY}/${RouteEnum.USER_AGREEMENT}`}
                        className={({ isActive, }) =>
                            !isActive
                                ? "privacy-menu__link"
                                : "privacy-menu__link privacy-menu__link_active"
                        }
                    >
                        Пользовательское соглашение
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default PrivacyMenu
