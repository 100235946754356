import { useEffect, type FC } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { RouteEnum } from "../../../main/routes/mainRoute"
import { useLoginWithCodeMutation } from "presentation/store/services/authApi"
import { profileApi } from "presentation/store/services/profileApi"

const OAuthPage: FC = () => {

    const [login] = useLoginWithCodeMutation()

    const [getProfileData] = profileApi.endpoints.getProfileData.useLazyQuery()

    const navigation = useNavigate()

    const [params] = useSearchParams()

    const code = params.get("code")

    const loginWithCode = async (): Promise<any> => {
        localStorage.clear()
        if (code) {
            await login({ code, })
                .then((res: any) => {
                    localStorage.setItem("token", res?.data?.access_token)
                })
                .catch(err => {
                    console.error(err)
                    navigation(`/${RouteEnum.LOGIN}`)
                })
            await getProfileData("")
                .then(() => {
                    navigation(`/${RouteEnum.MAIN}`)
                })
                .catch(err => {
                    console.error(err)
                    navigation(`/${RouteEnum.LOGIN}`)
                })
        } else {
            navigation(`/${RouteEnum.LOGIN}`)
        }
    }

    useEffect(() => {
        loginWithCode()
            .catch(err => console.error(err))
    }, [code])

    return (
        <>
        </>
    )
}

export default OAuthPage
