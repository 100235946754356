import { type FC, useEffect, useState } from "react"
import Modal from "presentation/components/Modal"
import { type GradationCardModalI } from "./interface"
import "./index.scss"
import { Carousel } from "react-responsive-carousel"
import { GradeCard } from "presentation/entities/grade/ui"
import Card from "presentation/components/Card"

const GradationCardModal: FC<GradationCardModalI> = ({ image, images, ...props }) => {
    const [selected, setSelected] = useState<number>(0)
    useEffect(() => {
        images?.forEach((item, index) => {
            if (item.url === image) {
                setSelected(index)
            }
        })
        console.log("test")
    }, [image, images])
    return (
        <Modal {...props}>
            <div className={"gradation-card-modal"}>
                <Card>
                    {images && (<Carousel
                        showStatus={false}
                        showThumbs={false}
                        infiniteLoop
                        transitionTime={0}
                        selectedItem={selected}
                        showIndicators={images.length > 1}
                    >
                        {
                            images.map((el, index) => (
                                <img
                                    key={index}
                                    className="gradation-card-modal__image"
                                    src={el.url}
                                    alt="photo"
                                />
                            ))
                        }
                    </Carousel>)}
                </Card>

            </div>
        </Modal>
    )
}

export default GradationCardModal
