import React, { type FC } from "react"
import { type ListProps } from "./interface"
import "./index.scss"

export const List: FC<ListProps> = (props) => {
    const { children, } = props
    return (
        <ul className={"List"}>
            {children}
        </ul>
    )
}
