import { type FC } from "react"
import "./index.scss"
import { dobroLogoSvg, logoSvg } from "../../assets/svg"
import { ArrowForwardIcon, Button, Redirect, UserIcon } from "../../components"
import { RouteEnum } from "../../../main/routes/mainRoute"
import { API_URL_OAUTH, DOBRO_URL } from "presentation/helper"

const LoginForm: FC = () => {

    const handleSignInClick = (): void => {
        window.location.href = API_URL_OAUTH
    }

    const handleSignUpClick = (): void => {
        window.location.href = `${DOBRO_URL}/register`
    }

    return (
        <div className="login-form">
            <img src={logoSvg} alt="logo"/>

            <div className="login-form__info">
                <img src={dobroLogoSvg} alt="dobro.ru" />
                <p className="login-form__info-desc">
                    Для использования сервиса требуется аккаунт на сайте <span className="login-form__link">dobro.ru</span>
                </p>
            </div>

            <div className="login-form__button-list">
                <Button
                    theme="outline"
                    leftIcon={<UserIcon />}
                    className="login-form__button"
                    onClick={handleSignUpClick}
                >
                    У меня нет аккаунта
                </Button>
                <Button
                    onClick={handleSignInClick}
                    rigthIcon={<ArrowForwardIcon/>}
                    className="login-form__button"
                >
                    Войти
                </Button>
            </div>

            <div className="login-form__about">
                <Redirect to={`/${RouteEnum.PRIVACY}`} theme="link">
                    <p>Информация о сервисе</p>
                </Redirect>
                <p className="login-form__copyright">Система Роста © 2023</p>
            </div>
        </div>
    )
}

export default LoginForm
