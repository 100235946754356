import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_URL } from "presentation/helper"

export interface NotificationI {
    id: string
    title: string
    message: string
    link: string
    read: boolean
    timestamp: string
    volunteerLevelId?: string
}
export type Notifications = NotificationI[]

export const notificationApi = createApi({
    reducerPath: "notificationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_URL}/user/notifications`,
        prepareHeaders (headers) {
            headers.set("authorization", `Bearer ${localStorage.getItem("token") || ""}`)
        },
    }),
    tagTypes: ["Notification"],
    endpoints: (builder) => ({
        getNotifications: builder.query<Notifications, void>({
            query: () => ({
                url: "/",
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id, }) => ({ type: "Notification" as const, id, })),
                        { type: "Notification", id: "LIST", }
                    ]
                    : [{ type: "Notification", id: "LIST", }],
        }),
        readNotifications: builder.mutation<void, string[]>({
            query: (ids) => ({
                url: "/",
                method: "PATCH",
                body: { ids, },
            }),
            invalidatesTags: (_, __, ids) => ids.map((id) => ({ type: "Notification" as const, id, })),
        }),
    }),
})

export const {
    useGetNotificationsQuery,
    useReadNotificationsMutation,
} = notificationApi
