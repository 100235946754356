import { type FC, useEffect, useRef, useState } from "react"
import GradeCardImage from "../../../grade/ui/GradeCardImage"
import "./index.scss"

interface Props {
    src?: string
    title?: string
}

const ProfileCourseCardItem: FC<Props> = ({ src, title, }) => {
    const [hide, setHide] = useState<string>("")
    const rootRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const handleClick = (event: MouseEvent): void => {
            const { target, } = event
            if (target instanceof Node && !rootRef.current?.contains(target)) {
                setHide("")
            }
        }
        window.addEventListener("click", handleClick)
        return () => {
            window.removeEventListener("click", handleClick)
        }
    }, [hide])
    const handleClickHide = (): void => {
        setHide(prev => prev ? "" : "active")
    }
    return (
        <div className="profile-course-card-item" ref={rootRef} onClick={handleClickHide}>
            <GradeCardImage
                src={src}
                alt={title}
                className={"profile-course-card-item__avatar" + " " + hide}
            />
            <div className={"profile-course-card-item__hover" + " " + hide}>
                <GradeCardImage
                    src={src}
                    alt={title}
                    className="profile-course-card-item__hover-image"
                />
                <p className="profile-course-card-item__sub-title">Награда</p>
                <p className="profile-course-card-item__title">
                    {title || "Значок Первой ступени"}
                </p>
            </div>
            <div className={"profile-course-card-item__content" + " " + hide} >
                <p className="profile-course-card-item__sub-title">Награда</p>
                <p className="profile-course-card-item__title">{title || "Значок Первой ступени"}</p>
            </div>

        </div>
    )
}

export default ProfileCourseCardItem
