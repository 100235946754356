import { RouteEnum } from "main/routes/mainRoute"
import { useEffect, type FC } from "react"
import { Outlet, useNavigate } from "react-router-dom"

const ProtectedRoute: FC = () => {

    const navigate = useNavigate()

    const isAuthorized = localStorage.getItem("token")

    useEffect(() => {
        if (!isAuthorized) {
            navigate(`/${RouteEnum.LOGIN}`)
        }
    }, [])

    return isAuthorized ? <Outlet/> : null
}

export default ProtectedRoute
