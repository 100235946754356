import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { profileApi } from "./services/profileApi"
import { authApi } from "./services/authApi"
import { volunteerApi } from "./services/volunteerApi"
import { notificationApi } from "./services/notificationApi"

const rootReducer = combineReducers({
    [profileApi.reducerPath]: profileApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [volunteerApi.reducerPath]: volunteerApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            profileApi.middleware,
            authApi.middleware,
            volunteerApi.middleware,
            notificationApi.middleware
        ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDisptach = typeof store.dispatch
