import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_URL } from "presentation/helper"

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
    }),
    endpoints: (builder) => ({
        loginWithCode: builder.mutation<{ access_token: string }, { code: string }>({
            query: (body) => ({
                url: "/user/auth/logincode",
                method: "POST",
                body,
            }),
        }),
    }),
})

export const {
    useLoginWithCodeMutation,
} = authApi
