import { type FC } from "react"
import { PrivacyLayout } from "../../layouts"
import { Redirect, Text } from "../../components"
import { RouteEnum } from "../../../main/routes/mainRoute"
import { smoothScroll } from "../../services/scroll.services"
import useMediaQuery from "presentation/hooks/useMediaQuery"

const PrivacyPage: FC = () => {
    const { isTablet, } = useMediaQuery()

    return (
        <PrivacyLayout>
            <Text.Header>
                Публичная политика обработки персональных данных
            </Text.Header>
            <Text.SubHeader>
                1. Общие положения
            </Text.SubHeader>
            <p>
                1.1 Настоящая Публичная политика обработки персональных данных Государственного
                автономного учреждения Оренбургской области &quot;Региональное агентство молодежных
                программ и проектов&quot; (далее – Политика) разработана в соответствии с требованиями
                Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» и определяет
                принципы обработки и обеспечения безопасности персональных данных в
                Государственном автономном учреждении Оренбургской области &quot;Региональное
                агентство молодежных программ и проектов&quot; (далее – ГАУ «РАМПиП»).
            </p>
            <p>
                1.2 Действие настоящей Политики распространяется на все процессы обработки
                персональных данных ГАУ «РАМПиП», как с использованием средств автоматизации,
                так и без использования таких средств, на все структурные подразделения и
                работников ГАУ «РАМПиП», участвующих в таких процессах, а также на
                информационные системы ГАУ «РАМПИП», используемые в процессах обработки
                персональных данных.
            </p>
            <Text.SubHeader>2. Принципы и правила обработки персональных данных</Text.SubHeader>

            <p>
                2.1 Обработка персональных данных осуществляется ГАУ «РАМПИП» на законной и
                справедливой основе и ограничивается достижением конкретных, заранее
                определенных и законных целей. Обработке подлежат только персональные данные,
                которые отвечают целям их обработки. Содержание и объем обрабатываемых ГАУ
                «РАМПИП» персональных данных соответствуют заявленным целям обработки,
                избыточность обрабатываемых данных не допускается.
            </p>
            <p>
                2.2 При обработке персональных данных ГАУ «РАМПИП» обеспечивается точность
                персональных данных, их достаточность и в необходимых случаях актуальность по
                отношению к целям обработки персональных данных. В ГАУ «РАМПИП» принимаются
                необходимые меры по удалению или уточнению неполных или неточных персональных
                данных
            </p>
            <p>
                2.3 Хранение персональных данных ГАУ «РАМПИП» осуществляется в форме,
                позволяющей определить субъекта персональных данных, не дольше, чем этого
                требуют цели обработки персональных данных, если срок хранения персональных
                данных не установлен федеральным законом, нормативно-правовым актом, договором,
                стороной которого, выгодоприобретателем или поручителем, по которому является
                субъект персональных данных. Обрабатываемые персональные данные подлежат
                уничтожению либо обезличиванию по достижении целей обработки, если иное не
                предусмотрено федеральным законом.
            </p>
            <p>
                2.4 Обработка персональных данных в ГАУ «РАМПИП» осуществляется как
                автоматизированным способом в информационных системах персональных данных,
                таки и без использования средств автоматизации (на бумажных носителях).
            </p>
            <p>
                2.5 ГАУ «РАМПИП» не принимает решения в отношении субъекта персональных
                данных, основанные исключительно на автоматизированной обработке.
            </p>
            <p>
                2.6 ГАУ «РАМПИП» с письменного согласия субъекта персональных данных публикует в
                общедоступных источниках его контактные данные (Ф.И.О, должность, номер рабочего
                телефона, e-mail и т. п.) с целью осуществления коммуникаций в процессе своей
                деятельности.
            </p>
            <p>
                2.7 Трансграничная передача персональных данных в ГАУ «РАМПИП» не
                осуществляется.
            </p>
            <p>
                2.8. Пользователь (субъект персональных данных) вправе отозвать свое согласие на
                обработку персональных данных. В случае отзыва Пользователем (субъектом
                персональных данных) согласия на обработку его персональных данных он может
                удалить предоставленные персональные данные.
                В случае отказа от согласия на обработку персональных данных и (или) не
                предоставления данных, которые требуются для поддержания и администрирования
                Платформы ДОБРОМОЛ56.РФ, Пользователь не сможет получить доступ к Сервисам
                Платформы ДОБРОМОЛ56.РФ или зарегистрироваться на ней.
            </p>
            <Text.SubHeader>
                3. Категории персональных данных, обрабатываемые в ГАУ
                «РАМПИП»
            </Text.SubHeader>
            <p>
                3.1 В ГАУ «РАМПИП» не осуществляется обработка специальных категорий
                персональных данных, касающихся расовой, национальной принадлежности,
                политических взглядов, религиозных или философских убеждений и интимной жизни
                субъектов персональных данных.
            </p>
            <p>
                3.2 В ГАУ «РАМПИП» допускается обработка сведений о состоянии здоровья
                работников в целях исполнения трудового законодательства, законодательства о
                государственной социальной помощи и пенсионного законодательства Российской
                Федерации.
            </p>
            <p>
                3.3 В ГАУ «РАМПИП» не осуществляется обработка биометрических персональных
                данных (специальные биометрические фотографии). При необходимости такая
                обработка осуществляется только с письменного согласия субъекта персональных
                данных.
            </p>
            <p>
                3.4 Перечень категорий субъектов персональных данных, чьи данные обрабатываются
                в ГАУ «РАМПИП», состав таких данных, цели и правовые основания для обработки
                определены в документе «Перечень персональных данных, обрабатываемых в ГАУ
                «РАМПИП»»
            </p>
            <Text.SubHeader>
                4. Поручение обработки персональных данных
            </Text.SubHeader>
            <p>
                4.1 ГАУ «РАМПИП» вправе поручить обработку персональных данных другим лицам с
                согласия субъекта персональных данных в соответствии со статьей 6 Федерального
                закона «О персональных данных».
            </p>
            <p>
                4.2 ГАУ «РАМПИП» поручает обработку персональных данных
                добровольцев(волонтеров) следующим лицам:
                <p>
                    – региональным представителям единой информационной системы в сфере развития
                    добровольчества (волонтерства) (далее – ЕИС), осуществляющим деятельность
                    согласно пункту 9 Правил функционирования ЕИС Постановления Правительства РФ от
                    17 августа 2019 г. № 1067 «О единой информационной системе в сфере развития
                    добровольчества (волонтерства)»;
                </p>
                <p>– автоматизированной информационной системе «Молодежь России»;</p>
                <p>
                    – федеральной государственной информационной системе «Единая система
                    идентификации и аутентификации в инфраструктуре, обеспечивающей
                    информационно-технологическое взаимодействие информационных систем,
                    используемых для предоставления государственных и муниципальных услуг в
                    электронной форме»;
                </p>
                <p>
                    – иным информационным системам, включая информационные системы федеральных
                    органов исполнительной власти и органов исполнительной власти субъектов
                    Российской Федерации, осуществляющих информационное взаимодействие с ЕИС;
                </p>
                <p>
                    – юридическим лицам и общественным объединениям, проводящим мероприятия с
                    привлечением добровольцев(волонтеров);
                </p>
                <p>
                    – юридическим лицам, которым принадлежат информационные системы,
                    взаимодействующие с ЕИС;
                </p>
                <p>
                    – юридическим лицам, которые обеспечивают проезд добровольцев (волонтеров),
                    организаторов добровольческой (волонтерской) деятельности, представителей
                    добровольческих (волонтерских) организаций до места проведения мероприятий с
                    участием добровольцев (волонтеров);
                </p>
                <p>
                    – юридическим лицам, которые обеспечивают проживание добровольцев (волонтеров),
                    организаторов добровольческой (волонтерской) деятельности, представителей
                    добровольческих (волонтерских) организаций в местах проведения мероприятий с
                    участием добровольцев (волонтеров);
                </p>
                <p>
                    – юридическим лицам, которые обеспечивают иные формы содействия проведению
                    мероприятий с участием добровольцев (волонтеров).
                </p>
            </p>
            <Text.SubHeader>
                5. Реализованные меры обеспечения безопасности персональных
                данных
            </Text.SubHeader>
            <p>
                5.1 В ГАУ «РАМПИП» реализованы следующие мероприятия по обеспечению
                безопасности персональных данных:
            </p>
            <p>а) назначено лицо, ответственное за обеспечение безопасности персональных данных;</p>
            <p>
                б) создана система защиты персональных данных информационных систем
                персональных данных;
            </p>
            <p>
                в) организован режим обеспечения безопасности помещений, в которых ведется
                обработка персональных данных, препятствующий возможности неконтролируемого
                проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в
                эти помещения;
            </p>
            <p>
                г) определен порядок предоставления допуска к обработке персональных данных и
                предоставления доступа к информационным системам персональных данных;
            </p>
            <p>д) организован учет машинных носителей персональных данных;</p>
            <p>
                е) обеспечена сохранность материальных (бумажных) носителей персональных
                данных;
            </p>
            <p>
                ж) проведена оценка вреда, который может быть причинен субъекту персональных
                данных в случае нарушения требований Федерального закона «О персональных
                данных»;
            </p>
            <p>
                з) осуществляется обнаружение фактов несанкционированного доступа к
                персональным данным и принятие мер по таким фактам;
            </p>
            <p>
                и) обеспечивается восстановление персональных данных, модифицированных или
                уничтоженных вследствие несанкционированного доступа к ним;
            </p>
            <p>
                к) проводятся периодические проверки соблюдения порядка обработки и обеспечения
                безопасности персональных данных.
            </p>
            {
                !isTablet &&
                <Redirect
                    header="Далее"
                    to={`${RouteEnum.PERSONAL_DATA}`}
                    callback={smoothScroll}
                >
                    <Text.Header>
                        Согласие на обработку персональных данных
                    </Text.Header>
                </Redirect>
            }
        </PrivacyLayout>
    )
}

export default PrivacyPage
