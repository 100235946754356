import { type FC } from "react"
import { PrivacyLayout } from "../../../layouts"
import { Redirect, Text } from "../../../components"
import { RouteEnum } from "../../../../main/routes/mainRoute"
import { smoothScroll } from "../../../services/scroll.services"
import useMediaQuery from "presentation/hooks/useMediaQuery"

const PrivacyPersonalDataPage: FC = () => {
    const { isTablet, } = useMediaQuery()

    return (
        <PrivacyLayout>
            <Text.Header>
                Согласие Пользователя на обработку персональных данных
            </Text.Header>
            <p>
                В соответствии со статьями 23, 24 Конституции Российской Федерации,
                Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»,
                Правилами обработки персональных данных, размещенными на
                сайте <a href="https://добромол56.рф">https://добромол56.рф</a> по адресу: <a href="https://добромол56.рф/ckfinder/userfiles/files/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf">
                    https://добромол56.рф/ckfinder/userfiles/files/Согласие%20Пользователя%20на%20обработку%20персо
                    нальных%20данных.pdf</a> (далее-Правила обработки ПДн),
                Я, субъект персональных данных, именуемый в дальнейшем Пользователь,
                отправляя информацию через формы обратной связи (далее, каждая из них и
                все в совокупности-Форма, Формы) при прохождении процедуры регистрации в
                целях использования сайта <a href="https://добромол56.рф">https://добромол56.рф</a> и его сервисов (далее -
                Сайт, Сервис), и в дальнейшем при использовании Сайта и/или его Сервисов,
                выражаю полное, безоговорочное и однозначное Согласие на обработку моих
                персональных данных (далее-Согласие) на следующих условиях:
            </p>
            <p>
                1. <strong>Согласие выдано ГАУ «РАМПиП», ИНН 5610128794 / КПП 561001001</strong>,
                расположенному по адресу: 460001, Оренбургская область, г. Оренбург, СНТ
                Дубки-2, (Юридический адрес: 460001, Оренбургская область, г. Оренбург, СНТ
                Дубки-2) (далее-Оператор).
            </p>
            <p>2. <strong>Согласие выдано на обработку персональных и иных данных</strong>, указанных Пользователем в Формах путем заполнения соответствующих текстовых полей и/или прикрепленных к Формам файлов, а именно следующих категорий:
                <p>
                    - фамилия, имя, отчество, адрес электронной почты (e-mail), номер телефона,
                    адрес регистрации, другая аналогичная информация, сообщённая о себе
                    Пользователем Сайта, на основании которой возможна его идентификация как
                    субъекта персональных данных.
                </p>
                <p>
                    - данных, которые автоматически передаются в процессе просмотра и при
                    посещении страниц Сайта: IP адрес, информация из cookies, информация о
                    браузере, время доступа, адрес посещаемой страницы, реферер (адрес
                    предыдущей страницы);
                </p>
                <p>- статистики о моих IP-адресах.</p>
            </p>
            <Text.SubHeader>3. Согласие выдано на обработку персональных данных в целях:</Text.SubHeader>
            <p>
                - исполнения соглашений по предоставлению доступа к Сайту, его Содержанию
                и/или Сервису, к функционалу Сервиса, для администрирования Сайта;
            </p>
            <p>- идентификации при регистрации на Сайте и/или при использовании Сервиса;</p>
            <p>- оказания услуг, обработки запросов и заявок;</p>
            <p>- установления обратной связи, включая направление уведомлений и запросов;</p>
            <p>- подтверждения полноты предоставленных персональных данных;</p>
            <p>- заключения договоров, осуществления взаиморасчетов;</p>
            <p>- сбора Оператором статистики;</p>
            <p>
                - улучшения качества работы Сайта и/или его Сервиса, удобства их
                использования и разработки новых сервисов и услуг;
            </p>
            <p>
                - проведения маркетинговых (рекламных) мероприятий, направления
                Оператором предложений и получения их Пользователем для продвижения на
                рынке услуг Оператора, в том числе, путем осуществления прямых контактов.
            </p>

            <p>
                Пользователь, настоящим, подтверждает, осведомлен и согласен, что для
                вышеуказанных целей, Оператор вправе собирать и использовать
                дополнительную информацию, связанную с Пользователем, получаемую в
                процессе доступа Пользователя к Сайту, его Содержанию и/или Сервису, или
                от третьих лиц, и включающую в себя данные о технических средствах (в том
                числе, мобильных устройствах) и способах технологического взаимодействия с
                Сайтом и/или его Сервисом (в т. ч. IP-адрес хоста, вид операционной системы
                Пользователя, тип браузера, географическое положение, данные о провайдере
                и иное), об активности Пользователя при использовании Сайта и/или его
                Сервиса, cookies, об информации об ошибках, выдаваемых Пользователю, о
                скачанных файлах, видео, инструментах, а также иные данные, получаемые
                установленными Правилами обработки ПДн способами; распоряжаться
                статистической информацией, связанной с функционированием Сайта и/или
                его Сервиса, а также информацией Пользователя для целей организации
                функционирования и технической поддержки Сайта и/или его Сервиса и
                исполнения условий законодательства Российской Федерации, и
                разработанных в соответствии с ним Правилами обработки ПДн.
            </p>
            <p>
                4. <strong>
                    Согласие выдано на обработку персональных данных смешанным
                    (автоматизированным и неавтоматизированным) способом.
                    В процессе обработки персональных данных Оператор вправе
                    осуществлять
                </strong>: сбор, запись, систематизацию, накопление, хранение,
                уточнение (обновление, изменение), извлечение, использование, передачу
                (распространение, предоставление, доступ), обезличивание, блокирование,
                удаление, уничтожение персональных данных Пользователя.
            </p>
            <p>5. <strong>Настоящим, Пользователь подтверждает, что</strong>:</p>
            <p>
                5.1. Ознакомлен и согласен с тем, что передача персональных данных
                Пользователя может осуществляться Оператором в объеме, необходимом
                для получения Пользователем доступа к Сайту, его Содержанию и/или его
                Сервису, следующим третьим лицам.
            </p>
            <p>
                5.2. Дает согласие на обработку своих персональных данных, указанных в
                пункте 2 настоящего согласия, лицам, указанным в пункте 1 настоящего
                согласия, а также подтверждает о разъяснении Оператором последствий
                непредоставления персональных данных, в случаях, когда предоставление
                таких данных является обязательным в соответствии с федеральным законом.
            </p>
            <p>
                5.3. Проинформирован о возможности отзыва согласия на основании
                положений Федерального закона от 27.07.2006 № 152-ФЗ «О персональных
                данных» путем личного обращения или направления письменного обращения
                (в том числе в форме электронного документа, подписанного простой
                электронной подписью или усиленной квалифицированной электронной
                подписью), на имя лиц, указанных в пункте 1 Согласия, в том числе
                проинформирован о праве отозвать Согласие в целях прекращения обработки
                лицом, указанным в пункте 1 Согласия, как всех указанных в пункте 2 Согласия
                персональных данных, так и отдельно биометрических персональных данных
                (если таковые будут запрошены Оператором и предоставлены Пользователем).
            </p>
            <p>
                5.4. Проинформирован, что лица, указанные в пункте 1 настоящего
                Согласия, вправе продолжать обработку персональных
                данных Пользователя после получения отзыва Согласия, а равно после
                истечения срока действия Согласия, при наличии оснований, предусмотренных
                частью 2 статьи 9 Федерального закона от 27.07.2006 № 152-ФЗ «О
                персональных данных».
            </p>
            <p>
                5.5. Дает согласие на получение рекламно-информационных материалов и
                ознакомлен Оператором о возможности и порядке совершения отказа от
                таковой.
            </p>
            <p>
                6. Согласие вступает в силу с момента его отправки и может быть
                отозвано Пользователем путем направления письменного заявления в
                произвольной форме в адрес Оператора по адресу электронной
                почты rampip56@mail.ru либо иным способом, позволяющим однозначно
                определить факт его получения адресатом.
            </p>
            <p>
                7. Согласие действует в течение неопределенного срока до момента его
                отзыва Пользователем. Согласие прекращает свое действие с даты,
                указанной в заявлении Пользователя об отзыве Согласия на обработку ПДн, но
                не ранее даты, следующей за датой фактического получения Оператором
                отзыва Согласия.
            </p>
            <p>
                8. Датой и временем формирования, подтверждения и отправки
                Согласия прошу считать момент маркировки соответствующего поля (либо
                нажатия на соответствующую кнопку) в Форме и/или нажатие на кнопку
                отправки Формы на любой странице Сайта и/или его Сервиса.
            </p>
            <p>
                9. Руководствуясь ч. 4 ст. 9 Федерального закона от 27.07.2006 № 152-ФЗ
                «О персональных данных», направляю через личное мобильное устройство
                или персональный компьютер путем прикрепления к специальной форме или
                при заполнении соответствующих полей такой формы при прохождении
                процедуры регистрации на Сайте свои персональные данные.
            </p>
            <p>
                10. Пользователь проинформирован, что ответственность перед ним за
                действия лица, обрабатывающего его персональные данные по
                поручению Оператора, несет Оператор, выдавший соответствующее
                поручение (согласие).
            </p>
            {
                !isTablet &&
                <Redirect
                    header="Далее"
                    to={`/${RouteEnum.PRIVACY}/${RouteEnum.USER_AGREEMENT}`}
                    callback={smoothScroll}
                >
                    <Text.Header>
                        Пользовательское соглашение
                    </Text.Header>
                </Redirect>
            }
        </PrivacyLayout>
    )
}

export default PrivacyPersonalDataPage
