import { type FC } from "react"
import { ReactComponent as ArrowSvg } from "../../assets/svg/arrow.svg"

const ArrowForwardIcon: FC = () => {
    return (
        <ArrowSvg/>
    )
}

export default ArrowForwardIcon
