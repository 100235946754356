import { type FC } from "react"
import { Card } from "../../../../components"
import { ProfileCardNextGrade, ProfileCourseList, ProfileLevel } from ".."
import { useGetVolunteerLevelListQuery } from "presentation/store/services/volunteerApi"
import "./index.scss"
import { profileDataDefault, useGetProfileDataQuery } from "presentation/store/services/profileApi"
import { type ProgressForLevelPercentage } from "presentation/store/model/user"

type LevelTasks = Array<{
    title: string
    type: string
    completed: boolean | number[]
    percentage?: ProgressForLevelPercentage
}> | undefined

const ProfileMyGrade: FC = () => {
    const { data: profileData = profileDataDefault, isLoading: loadingProfile, } = useGetProfileDataQuery("")
    const { data: levelsData, isLoading: loadingLevels, } = useGetVolunteerLevelListQuery("")

    const hours = profileData.statistics.verifiedHours
    const events = profileData.statistics.eventsCount
    let myLevelIdx = -1
    let myLevelName = "Загрузка..."
    let levelTasks: LevelTasks =
        ["Загружаем...", "Задания...", "Скоро будут :)"]
            .map(title => ({ title, })) as LevelTasks

    if (!(loadingLevels && loadingProfile) && (levelsData && profileData)) {
        myLevelIdx = levelsData.findIndex(lvl => lvl.id === profileData?.user.volunteerLevelId)

        myLevelName = myLevelIdx >= 0
            ? levelsData[myLevelIdx].name
            : "I уровень совсем скоро! :)"
        const nextLevel = levelsData[myLevelIdx + 1]

        levelTasks = profileData.progressForLevels
            .find(lvl => lvl.id === nextLevel.id)
            ?.requirements
            .map((req, i) => ({
                ...req,
                title: nextLevel.requirements[i].title,
            }))
    }

    return (
        <section className="profile-my-grade">
            <h2 className="profile-my-grade__header">Мой рост</h2>
            <div className="profile-my-grade__top">
                <Card className="profile-my-grade__level">
                    <ProfileLevel level={myLevelIdx + 1}/>
                    <div className="profile-my-grade__my-level">
                        <p className="profile-my-grade__sub-title">Мой уровень</p>
                        <p className="profile-my-grade__title">
                            {
                                myLevelName
                            }
                        </p>
                    </div>
                </Card>
                <div className="profile-my-grade__right">
                    <Card className="profile-my-grade__card">
                        <p className="profile-my-grade__sub-title">Часов волонтерства</p>
                        <p className="profile-my-grade__title">{hours}</p>
                    </Card>
                    <Card className="profile-my-grade__card">
                        <p className="profile-my-grade__sub-title">Мероприятий посетил</p>
                        <p className="profile-my-grade__title">{events}</p>
                    </Card>
                </div>
            </div>
            <div className="profile-my-grade__bottom">
                <ProfileCardNextGrade levelTasks={levelTasks} />
                <ProfileCourseList />
            </div>
        </section>
    )
}

export default ProfileMyGrade
