import { useId, type FC, type MouseEventHandler } from "react"
import "./index.scss"
import { notificationSvg } from "../../assets/svg"
import NotificationItem from "./NotificationItem"
import { useGetNotificationsQuery, useReadNotificationsMutation } from "presentation/store/services/notificationApi"
import Level from "presentation/entities/profile/ui/ProfileLevel"

const Notification: FC = () => {
    const id = useId()
    const { data, isFetching, } = useGetNotificationsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
    })
    const [triggerRead] = useReadNotificationsMutation()

    const unreadNotifications = data?.filter(n => !n.read) || []
    const hasUnread = unreadNotifications.length > 0 || false

    let time: number
    const handleReadNotifications: MouseEventHandler<HTMLInputElement> = (e): void => {
        if (!data || !hasUnread) return

        // уведомление считается прочитанным только когда
        // пользователь посмотрит на него 1.5 секунды
        if (!e.currentTarget.checked) {
            if ((Date.now() - time) >= 1500) {
                void triggerRead(unreadNotifications.map(n => n.id))
            }
        } else time = Date.now()
    }

    return (
        <div className="notification">
            <input
                id={`notification-${id}`}
                className="notification__checkbox"
                type="checkbox"
                onClick={handleReadNotifications}
            />
            <label
                htmlFor={`notification-${id}`}
                className="notification__closed"
            />
            <label
                htmlFor={`notification-${id}`}
                className="notification__label"
                data-unread={hasUnread}
            >
                <img src={notificationSvg} alt="notification" />
            </label>
            <div className="notification__list">
                {(isFetching && !data) &&
                    <p className="notification__list__message">
                    Загрузка...
                    </p>
                }

                {(data && data?.length === 0) &&
                    <p className="notification__list__message">
                    У вас нет уведомлений
                    </p>
                }

                {data && data?.length > 0 && data.map((n, i) => (
                    <NotificationItem
                        key={`notification-${i}`}
                        title={n.title}
                        message={n.message}
                        timestamp={n.timestamp}
                        viewed={n.read}
                        icon={<Level level={1} />}
                    />
                ))}
            </div>
        </div>
    )
}

export default Notification
