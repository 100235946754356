import { type FC } from "react"
import "./index.scss"
import { Notification, Profile } from ".."
import { dobrovoltsyLogoSvg, logoSvg } from "../../assets/svg"
import { useNavigate } from "react-router-dom"

const Header: FC = () => {
    const navigation = useNavigate()

    return (
        <header className="header">
            <div className="header__logo-container">
                <img
                    className="header__logo"
                    onClick={() => {
                        navigation("/")
                    }}
                    src={logoSvg}
                    alt="logo"
                />
                <a className="header__logo" href="https://добромол56.рф/">
                    <img alt="добромол56.рф" src={dobrovoltsyLogoSvg} />
                </a>
            </div>
            <div className="header__panel">
                <Notification />
                <Profile />
            </div>
        </header>
    )
}

export default Header
