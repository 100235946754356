const getEnvVar = (key: string): string => {
    const envVariable = process.env[key]

    if (envVariable === undefined) {
        throw new Error(`env variable ${key} is required`)
    }
    return envVariable
}

const API_URL = getEnvVar("REACT_APP_API_URL")
console.log(API_URL)
const DOBRO_URL = getEnvVar("REACT_APP_DOBRO_URL")
const API_URL_OAUTH = getEnvVar("REACT_APP_API_URL_OAUTH")

export {
    API_URL,
    DOBRO_URL,
    API_URL_OAUTH
}
