import { type FC } from "react"
import { Card } from "../../../../components"
import "./index.scss"
import { ProfileCourseCardItem, ProfileCourseCardLevel, ProfileLevel } from ".."
import { type VolunteerLevel } from "presentation/store/model/volunteer"
import { useGetProfileDataQuery } from "presentation/store/services/profileApi"

const ProfileCourseCard: FC<{ level: VolunteerLevel | undefined }> = ({ level, }) => {
    const { data: profile, } = useGetProfileDataQuery("")

    if (!level) {
        return (
            <Card className="profile-course-card">
                Загрузка...
            </Card>
        )
    }

    const userAwards = profile?.user?.awards || []
    const awardsForLevelReceived = level.awards.every((a) => userAwards.map(a => a.id).includes(a.id))

    return (
        <Card className="profile-course-card">

            <div className="profile-course-card__top">
                <div className="profile-course-card__header">
                    <ProfileLevel level={level.order / 100} />
                    <p className="profile-course-card__title">
                        {level.name}
                    </p>
                </div>
                <ProfileCourseCardLevel level={level} />
            </div>
            {awardsForLevelReceived
                ? <p className="profile-course-card__award-message">Награда получена!</p>
                : null}

            <div className="profile-course-card__bottom">
                <ProfileCourseAwards level={level} received={awardsForLevelReceived} />
            </div>

        </Card>
    )
}

const ProfileCourseAwards: FC<{ level: VolunteerLevel, received: boolean }> = ({ level, }) => {
    const awardElementsList: JSX.Element[] = level.awards.map((award, i) => {
        const src = award.images[0].url

        return (
            <ProfileCourseCardItem
                key={i}
                src={src}
                title={award.name}
            />
        )
    })

    return (<>{awardElementsList}</>)
}

export default ProfileCourseCard
