import { type FC } from "react"
import { Card } from "../../../../components"
import GradeCardImage from "../GradeCardImage"
import { Carousel } from "react-responsive-carousel"
import { type GradeCardI } from "./interface"
import "./index.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const GradeCard: FC<GradeCardI> = ({ onClick, images, }) => {
    return (
        <Card
            className="grade-card"
            border="brown"
        >
            <Carousel
                showStatus={false}
                showThumbs={false}
                infiniteLoop
                showIndicators={images.length > 1}
            >
                {
                    images.map((el, index) => (
                        <div key={`grade-card-${index}`}>
                            <p className="grade-card__signature">
                                Награда
                            </p>
                            <p className="grade-card__title">
                                {el.title}
                            </p>
                            <GradeCardImage onClick={() => {
                                onClick(el.url)
                            }} src={el.url || ""} alt={el.title} />
                        </div>
                    ))
                }
            </Carousel>
        </Card>
    )
}

export default GradeCard
