export enum RouteEnum {
    MAIN = "",
    INFORMATION = "information",
    LOGIN = "login",
    PROFILE = "profile",
    OAUTH = "auth/logincb",
    // privacy
    PRIVACY = "privacy",
    PERSONAL_DATA = "personal-data",
    USER_AGREEMENT = "user-agreement"
}
