import { type FC } from "react"
import ProfileCourseCard from "../ProfileCourseCard"
import Carousel from "../../../../components/Carousel"
import { useGetVolunteerLevelListQuery } from "presentation/store/services/volunteerApi"

const ProfileCourseCarusel: FC = () => {
    const { data: levels, isFetching, } = useGetVolunteerLevelListQuery("")

    if (!levels || isFetching) {
        // чтобы минимум 6 карточек было, если левелы ещё грузятся
        const placeholder = Array.from({ length: 6, })
        return (
            <Carousel>
                {placeholder.map((_, idx) => {
                    return <ProfileCourseCard key={idx} level={undefined} />
                })}
            </Carousel>
        )
    }

    return (
        <Carousel>
            {levels.map((l, idx) => {
                return <ProfileCourseCard key={idx} level={l} />
            })}
        </Carousel>
    )
}

export default ProfileCourseCarusel
