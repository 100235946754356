import { type FC } from "react"
import { createPortal } from "react-dom"
import { type ModalI } from "./interface"
import "./index.scss"

const Modal: FC<ModalI> = ({ isActive, toggleActivity, children, }) => {

    return (
        <>
            {
                isActive &&
                createPortal(
                    <div className="modal">
                        <div className="modal__background" onClick={toggleActivity}/>
                        <div className="modal__container">
                            {
                                children
                            }
                        </div>
                    </div>,
                    document.body
                )
            }
        </>
    )
}

export default Modal
