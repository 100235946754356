import { useEffect, type FC } from "react"
import BaseLayout from "../../layouts/BaseLayout"
import { ProfileHeader, ProfileMyGrade } from "../../entities/profile/ui"
import ProfileCourseCarusel from "../../entities/profile/ui/ProfileCourseCarusel"
import { useGetProfileDataQuery } from "presentation/store/services/profileApi"
import { fullNameFromFio } from "presentation/helper"
import { useGetNotificationsQuery } from "presentation/store/services/notificationApi"

const PersonalAccountPage: FC = () => {

    const { data, } = useGetProfileDataQuery("")
    const { refetch: refetchNotifications, } = useGetNotificationsQuery()

    useEffect(() => {
        if (data?.newLevelReceived) {
            void refetchNotifications()
        }
    }, [data?.newLevelReceived])

    return (
        <BaseLayout>
            <ProfileHeader
                userData={{
                    birthday: data?.birthday || "",
                    city: data?.settlement?.settlement || "",
                    volunteerId: data?.user?.volunteerId || 0,
                    fullname: fullNameFromFio(data?.fio),
                }}
            />
            <ProfileMyGrade />
            <ProfileCourseCarusel />
        </BaseLayout>
    )
}

export default PersonalAccountPage
