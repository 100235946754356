import { type FC } from "react"
import { BaseLayout } from "../../layouts"
import { GradationCard } from "../../components"
import { useGetVolunteerLevelListQuery } from "presentation/store/services/volunteerApi"
import { Card } from "presentation/components"
import { Li, List } from "presentation/components/List"

const GradeSystemPage: FC = () => {

    const { data, } = useGetVolunteerLevelListQuery("")

    return (
        <BaseLayout>
            <Card border={ "brown" } className={"base-layout__info"}>
                <div className={"base-layout__info_text"}>
                    Комплексная система мотивации и поощрения волонтеров Оренбургской области
                    «Система роста» позволяет добровольцам региона получить реальное вознаграждение
                    за заработанные ими волонтерские часы и пройденные курсы! <br/>  <br/>
                    Информация о количестве заработанных часов и пройденных курсах
                    автоматически будет переходить в твой личный кабинет.
                </div>
                <div className={"base-layout__info_list"}>
                    <h4>
                        Это работает очень просто:
                    </h4>
                    <List>
                        <Li>Зарабатывай верифицированные волонтерские часы на Добро.ру в период реализации программы мотивации;</Li>
                        <Li>Проходи образовательные курсы «Добро.Университета»;</Li>
                        <Li>Авторизуйся на сайте «Системы роста», используя свой аккаунт «Добро.ру»;</Li>
                        <Li>Отслеживай свой прогресс по ступеням и получай вознаграждение!</Li>
                    </List>
                </div>
            </Card>
            {
                data?.map((grade, index: number) => {
                    const images = grade?.awards?.map(({ name, images, }, idx) => {
                        return {
                            title: name,
                            url: images[0].url,
                        }
                    })

                    return <GradationCard
                        key={`gradation-card-${index}`}
                        name={grade?.name}
                        requirements={grade?.text}
                        images={images}
                        levelOrder={index}
                    />
                })
            }
        </BaseLayout>
    )
}

export default GradeSystemPage
