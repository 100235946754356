import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_URL } from "presentation/helper"
import { type UserI } from "../model/user"

export const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders (headers) {
            headers.set("authorization", `Bearer ${localStorage.getItem("token") || ""}`)
        },
    }),
    tagTypes: ["Profile"],
    endpoints: (builder) => ({
        getProfileData: builder.query<UserI, string>({
            query: () => ({
                url: "/user/me",
            }),
        }),
    }),
})

export const {
    useGetProfileDataQuery,
} = profileApi

export const profileDataDefault: UserI = {
    id: 0,
    birthday: new Date().toDateString(),
    courses: [],
    settlement: {
        countryName: "",
        settlement: "",
    },
    fio: {
        first_name: "",
        last_name: "",
        second_name: "",
    },
    statistics: {
        verifiedHours: 0,
        eventsCount: 0,
    },
    user: {
        email: "",
        id: 0,
        volunteerId: 0,
        volunteerLevelId: null,
        imageUrl: "",
    },
    progressForLevels: [
        {
            id: "beginnervolunteerI",
            requirements: [{
                type: "loading",
                completed: false,
                percentage: {
                    value: 0,
                    items: 0,
                    maxItems: 1,
                },
            }],
        }
    ],
}
