import { type FC } from "react"
import "./index.scss"
import { Progress } from "../../../../components"
import TaskProgress from "../TaskProgress"
import { useGetProfileDataQuery } from "presentation/store/services/profileApi"
import { type VolunteerLevel } from "presentation/store/model/volunteer"
import { type ProgressForLevelPercentage, type UserI } from "presentation/store/model/user"

interface Props {
    level: VolunteerLevel
}

interface ProgressForLevel {
    percentage: number
    tasks: Array<{
        type: string
        completed?: boolean | number[]
        percentage?: ProgressForLevelPercentage
        title: string
    }>
    requiredCount: number
    completedCount: number
}

const ProfileCourseCardLevel: FC<Props> = ({ level, }) => {
    const { data: userData, isFetching: fetchingUserData, } = useGetProfileDataQuery("")
    const { percentage = 0, requiredCount = 0, completedCount = 0, tasks: requirements = [], } =
        fetchingUserData ? {} : calculateProgress(userData!, level)

    return (
        <div className="profile-course-card-level">
            <Progress value={percentage} itemsCount={completedCount} maxItemsCount={requiredCount} />
            <div className="profile-course-card-level__info">
                <p className="profile-course-card-level__title">
                    Требования для получения уровня
                </p>
                <div className="profile-course-card-level__list">
                    {requirements.map((req) => (
                        <TaskProgress
                            key={req.type}
                            theme="white"
                            task={req}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

const calculateProgress = (userData: UserI, level: VolunteerLevel): ProgressForLevel => {
    if (!userData) {
        return {
            percentage: 0,
            tasks: [],
            requiredCount: 1,
            completedCount: 0,
        }
    }

    const progressForLevel = userData.progressForLevels.find(p => p.id === level.id)
    const requiredCount = level.requirements.length
    const completedCount = progressForLevel?.requirements.reduce((acc, cur) => {
        if (cur.completed) {
            acc++
        }
        return acc
    }, 0) || 0

    const percentage = Math.floor(completedCount / requiredCount * 100)
    const tasks = level.requirements.map((req) => ({
        ...req,
        ...progressForLevel?.requirements
            .find((progReq) => progReq.type === req.type),
    }))

    return {
        percentage,
        requiredCount,
        completedCount,
        ...progressForLevel,
        ...level,
        tasks,
    }
}

export default ProfileCourseCardLevel
