import { type FC } from "react"
import { type ITextHeader } from "./interface"
import "./index.scss"

const TextHeader: FC<ITextHeader> = (props) => {
    return (
        <h1 className="text-header" {...props}/>
    )
}

export default TextHeader
