import { type UserI } from "presentation/store/model/user"

export function fullNameFromFio (fio?: UserI["fio"]): string {
    const first = fio?.first_name || ""
    const second = fio?.second_name || ""
    const last = fio?.last_name || ""
    return [last, first, second].join(" ")
}

export * from "./env"
