import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_URL } from "presentation/helper"
import { type VolunteerLevels, type VolunteerBookI } from "../model/volunteer"

export const volunteerApi = createApi({
    reducerPath: "graduateApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders (headers) {
            headers.set("Authorization", `Bearer ${localStorage.getItem("token") || ""}`)
        },
    }),
    tagTypes: ["Graduate", "Graduates"],
    endpoints: (builder) => ({
        getVolunteerLevelList: builder.query<VolunteerLevels, string>({
            query: () => ({
                url: "/user/volunteer-level/list",
            }),
        }),
        getVolunteerBook: builder.query<VolunteerBookI, string>({
            query: (volId) => ({
                url: `/user/volunteers/${volId}/evk`,
            }),
        }),
    }),
})

export const {
    useGetVolunteerLevelListQuery,
    useGetVolunteerBookQuery,
} = volunteerApi
