import { type FC } from "react"
import "./index.scss"
import { NavLink, useNavigate } from "react-router-dom"
import { RouteEnum } from "../../../main/routes/mainRoute"
import { useGetProfileDataQuery } from "presentation/store/services/profileApi"
import { fullNameFromFio } from "presentation/helper"

const Profile: FC = () => {

    const navigate = useNavigate()

    const { data, } = useGetProfileDataQuery("")

    const token = localStorage.getItem("token")
    const name = fullNameFromFio(data?.fio)
    const avatar = data?.user.imageUrl

    return (
        <div className="profile">
            {
                token &&
                <>
                    <img
                        className="profile__avatar"
                        src={avatar || undefined}
                        alt="avatar"
                        onClick={() => { navigate(`/${RouteEnum.PROFILE}`) }}
                    />
                    <div className="profile__content">
                        <p className="profile__fullname">{ name }</p>
                        <NavLink
                            className="profile__link"
                            to={`/${RouteEnum.PROFILE}`}
                        >
                            Мой профиль
                        </NavLink>
                    </div>
                </>
            }
        </div>
    )
}

export default Profile
