import { type FC } from "react"
import { Card } from "../../../../components"
import TaskProgress from "../TaskProgress"
import { type ProfileCardNextGradeI } from "./interface"
import "./index.scss"

const ProfileCardNextGrade: FC<ProfileCardNextGradeI> = ({ levelTasks, }) => {
    return (
        <Card className="profile-card-next-grade">
            <p className="profile-card-next-grade__header">
                Задания для получения следующего уровня
            </p>

            <div className="profile-card-next-grade__list">
                {
                    levelTasks?.map((task, index) => (
                        <TaskProgress
                            key={`level-task-${index}`}
                            task={task}
                        />
                    ))
                }
            </div>
        </Card>
    )
}

export default ProfileCardNextGrade
