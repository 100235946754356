import { type FC } from "react"
import { Card } from "../../../../components"
import "./index.scss"
import { type GoalsCardI } from "./interface"
import { Li, List } from "presentation/components/List"

const GoalsCard: FC<GoalsCardI> = ({ goals, }) => {
    return (
        <Card className="goals-card">
            <p className="goals-card__title">
                Требования для получения уровня
            </p>
            <List>
                {
                    goals.map((goal, index) => (
                        <Li key={`goal-${index}`}>
                            { goal }
                        </Li>
                    ))
                }
            </List>
            {/* <div className="goals-card__list"> */}

            {/* </div> */}
        </Card>
    )
}

export default GoalsCard
