import { type FC } from "react"
import { Routes, Route } from "react-router-dom"
import { RouteEnum } from "../mainRoute"
import {
    OAuthPage,
    PersonalAccountPage,
    PrivacyPage,
    PrivacyPersonalDataPage,
    PrivacyUserAgreementPage,
    SignInPage
} from "../../../presentation/pages"
import GradeSystemPage from "../../../presentation/pages/GradeSystem"
import { ProtectedRoute } from "presentation/components"

const Router: FC = () => {
    return (
        <Routes>
            <Route
                path={`/${RouteEnum.LOGIN}`}
                element={<SignInPage />}
            />
            <Route
                path={`/${RouteEnum.OAUTH}`}
                element={<OAuthPage />}
            />
            <Route
                path={`/${RouteEnum.PRIVACY}`}
            >
                <Route
                    index
                    element={<PrivacyPage />}
                />
                <Route
                    path={RouteEnum.PERSONAL_DATA}
                    element={<PrivacyPersonalDataPage />}
                />
                <Route
                    path={RouteEnum.USER_AGREEMENT}
                    element={<PrivacyUserAgreementPage />}
                />
            </Route>
            {/* //* protected routes */}
            <Route
                element={<ProtectedRoute/>}
            >
                <Route
                    path={`/${RouteEnum.MAIN}`}
                    element={<GradeSystemPage />}
                />
                <Route
                    path={`/${RouteEnum.PROFILE}`}
                    element={<PersonalAccountPage />}
                />
            </Route>
        </Routes>
    )
}

export default Router
