import { type FC } from "react"
import "./index.scss"
import { type IAuthLayout } from "./interface"
import Background from "presentation/assets/images/wrapper.png"
import { ReactComponent as CubeFirstRankSvg } from "../../assets/svg/cube_first_rank.svg"
import { ReactComponent as CubeSecondRankSvg } from "../../assets/svg/cube_second-rank.svg"

const AuthLayout: FC<IAuthLayout> = (props) => {
    const { children, ...othersProps } = props

    return (
        <main className="auth-layout" {...othersProps}>
            <img className={"auth-layout__background"} src={Background}/>
            <div className="auth-layout__cubes">

                {/* <cubeFirstRankSvg /> */}
                {/* <img */}
                {/*    className="auth-layout__green-cube" */}
                {/*    src={cubeSecondRankSvg} */}
                {/*    alt="cube-second-rank" */}
                {/* /> */}
                <CubeSecondRankSvg className={"auth-layout__green-cube"}/>
                <CubeFirstRankSvg className={"auth-layout__brown-cube"}/>
            </div>
            <div className="auth-layout__form">
                {children}
            </div>
        </main>
    )
}

export default AuthLayout
