import { type FC } from "react"
import "./index.scss"
import { type ICard } from "./interface"

const Card: FC<ICard> = (props) => {
    const { border, className, children, ...othersProps } = props

    const currentClasses = ["card"]

    if (border) {
        currentClasses.push("card_border_".concat(border))
    }

    if (className) {
        currentClasses.push(className)
    }

    return (
        <div className={currentClasses.join(" ")} {...othersProps}>
            {children}
        </div>
    )
}

export default Card
