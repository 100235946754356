import { type FC, useState } from "react"
import "./index.scss"
import { GoalsCard, GradeCard } from "../../entities/grade/ui"
import GradationCardModal from "./GradationCardModal"
import { type GradationCardI } from "./interface"
import { levelsToCubes, levelsToRoman } from "presentation/entities/profile/ui/ProfileLevel"

const GradationCard: FC<GradationCardI> = ({ name, requirements, images, levelOrder, }) => {

    const [isModalActive, setIsModalActive] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<string>("")

    const toggleModalActivity = (): void => setIsModalActive(prev => !prev)

    return (
        <article className="gradation-card">
            <GradationCardModal image={selectedImage} images={images} isActive={isModalActive} toggleActivity={() => toggleModalActivity()} />
            <div className="gradation-card__left">
                <div className="gradation-card__level-icon">
                    <img
                        src={levelsToCubes[levelOrder]}
                        alt="level"
                    />
                    <p className="gradation-card__level">
                        {levelsToRoman[levelOrder]}
                    </p>
                </div>
            </div>
            <div className="gradation-card__content">
                <div className="gradation-card__header">
                    <div className="gradation-card__level-icon gradation-card__level-icon_mobile">
                        <img
                            src={levelsToCubes[levelOrder]}
                            alt="level"
                        />
                        <p className="gradation-card__level">
                            {levelsToRoman[levelOrder]}
                        </p>
                    </div>
                    <h1 className="gradation-card__title">{ name }</h1>
                </div>
                <div className="gradation-card__requirements">
                    <GradeCard
                        images={images}
                        onClick={(img: string) => {
                            toggleModalActivity()
                            setSelectedImage(img)
                        }}
                    />
                    <GoalsCard goals={requirements}/>
                </div>
            </div>
        </article>
    )
}

export default GradationCard
