import { type FC } from "react"
import "./index.scss"
import { type IButton } from "./intreface"
import { themeServices } from "../../services/theme.services"

const Button: FC<IButton> = (props) => {
    const { children, rigthIcon, leftIcon, theme, size, className, ...othersProps } = props

    const currentClasses = ["button"]

    if (theme) {
        themeServices(theme, currentClasses)
    }

    if (size) {
        currentClasses.push("button_size_".concat(size))
    }

    if (className) {
        currentClasses.push(className)
    }

    return (
        <button className={currentClasses.join(" ")} {...othersProps}>
            {leftIcon}
            {children}
            {rigthIcon}
        </button>
    )
}

export default Button
