import { type FC } from "react"
import { type ILevel } from "./interface"
import "./index.scss"
import { cubeLevelOne, cubeLevelTwo, cubeLevelThree } from "../../../../assets/svg"

export const levelsToRoman = ["I", "II", "III", "IV", "V", "VI"]
export const levelsToCubes = [cubeLevelOne, cubeLevelOne, cubeLevelTwo, cubeLevelThree, cubeLevelThree, cubeLevelThree]

const Level: FC<ILevel> = (props) => {
    const { level, } = props

    const iconSrc = levelsToCubes[level - 1]
    return (
        <div className="level">
            {iconSrc && <img src={iconSrc} alt="" />}
            <div className="level__block">
                {levelsToRoman[level - 1]}
            </div>
        </div>
    )
}

export default Level
