import { type FC } from "react"
import "./index.scss"
import { type IPrivacyLayout } from "./interface"
import { Header, PrivacyMenu } from "../../components"

const PrivacyLayout: FC<IPrivacyLayout> = (props) => {

    const { children, ...othersProps } = props

    return (
        <>
            <Header />
            <main className="privacy-layout" {...othersProps}>
                <PrivacyMenu />
                <div className="privacy-layout__content">
                    {children}
                </div>
            </main>
        </>
    )
}

export default PrivacyLayout
