import { type FC } from "react"
import "./index.scss"
import { Progress } from "../../../../components"
import { type ITaskProgress } from "./interface"

const TaskProgress: FC<ITaskProgress> = (props) => {
    const { theme, task, ...othersProps } = props

    const currentClasses = ["task-progress"]

    if (theme) {
        currentClasses.push("task-progress_theme_".concat(theme))
    }

    return (
        <div className={currentClasses.join(" ")} {...othersProps}>
            <p>{ task?.title || "Профиль заполнен на 100%"}</p>
            <Progress
                value={task?.percentage?.value}
                itemsCount={task?.percentage?.items}
                maxItemsCount={task?.percentage?.maxItems}
            />
        </div>
    )
}

export default TaskProgress
