import { type FC } from "react"
import { type IGradeCardImage } from "./interface"
import "./index.scss"

const GradeCardImage: FC<IGradeCardImage> = (props) => {
    const { className, } = props

    const currentClasses = ["grade-card-image"]

    if (className) {
        currentClasses.push(className)
    }

    return (
        <div className={currentClasses.join(" ")} {...props}>
            <img src={props.src} alt={props.alt} />
        </div>
    )
}

export default GradeCardImage
