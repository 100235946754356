import { type FC } from "react"
import { PrivacyLayout } from "../../../layouts"
import { Redirect, Text } from "../../../components"
import { RouteEnum } from "../../../../main/routes/mainRoute"
import { smoothScroll } from "../../../services/scroll.services"
import useMediaQuery from "presentation/hooks/useMediaQuery"

const PrivacyUserAgreementPage: FC = () => {
    const { isTablet, } = useMediaQuery()

    return (
        <PrivacyLayout>
            <Text.Header>
                Пользовательское соглашение
            </Text.Header>
            <p>
                Информационной системы добровольческих и молодежных инициатив
                Оренбургской области
                (<a href="http://www.добромол56.рф">www.добромол56.рф</a>)
            </p>
            <Text.SubHeader>
                1. Общие положения
            </Text.SubHeader>
            <p>
                1.1. Настоящее Пользовательское соглашение (далее – «настоящее
                Соглашение») разработано и размещено ресурсным центром добровольческих и
                молодежных инициатив государственного автономного учреждения Оренбургской
                области «Региональное агентство молодежных программ и проектов»,
                являющейся правообладателем и оператором Информационной системы
                добровольческих и молодежных инициатив Оренбургской области
                (<a href="http://www.добромол56.рф">www.добромол56.рф</a>)
                Местонахождение: 4600000 Оренбургская область, г. Оренбург, СНТ Дубки-2
                (ОГРН 109568016630), далее именуемая <strong>Ресурсным центром</strong>.
            </p>
            <p>
                1.2. Настоящее Соглашение размещено на официальной интернет-платформе -
                Информационной системы добровольческих и молодежных инициатив
                Оренбургской области (далее – <strong>ЕИС ДОБРОМОЛ56.РФ</strong>) и является
                официальным документом.
            </p>
            <p>1.3. При регистрации и/или авторизации посредством ЕИС ДОБРОМОЛ56.РФ, в
                каждом доступе или фактическом использовании ЕИС ДОБРОМОЛ56.РФ,
                физическое лицо, которое использует ЕИС ДОБРОМОЛ56.РФ (далее
                – <strong>Пользователь</strong>), соглашается с условиями настоящего Соглашения в редакции,
                действующей на момент фактического использования ЕИС ДОБРОМОЛ56.РФ.
            </p>
            <p>
                1.4. Настоящее Соглашение может быть изменено и/или дополнено <strong>
                    Ресурсным
                    центром
                </strong> в одностороннем порядке. Настоящее Соглашение является открытым и
                общедоступным документом. Действующая редакция настоящего Соглашения
                размещена по
                адресу:  <a href="https://добромол56.рф/ckfinder/userfiles/files/Пользовательское%20соглашение.pdf">https://добромол56.рф/ckfinder/userfiles/files/Пользовательское%20соглашение.pdf</a>
            </p>
            <p>
                1.5. <strong>Ресурсным центром</strong> рекомендует Пользователям регулярно проверять
                условия настоящего Соглашения на предмет его изменения и/или дополнения.
                Продолжение использования ЕИС ДОБРОМОЛ56.РФ Пользователем после
                внесения изменений и/или дополнений в настоящее Соглашение означает
                принятие и согласие Пользователя с такими изменениями и/или дополнениями.
            </p>
            <p>
                1.6. Использование ЕИС ДОБРОМОЛ56.РФ означает полное и безоговорочное
                согласие с условиями настоящего Соглашения, в соответствии со ст. 438
                Гражданского кодекса Российской Федерации.
            </p>
            <Text.SubHeader>
                2. Предмет Соглашения
            </Text.SubHeader>
            <p>
                2.1. По условиям настоящего Соглашения <strong>Ресурсным центром</strong> предоставляет
                возможность использования на безвозмездной основе принадлежащей ей
                Информационной системы добровольческих и молодежных инициатив
                Оренбургской области  - ЕИС ДОБРОМОЛ56.РФ, в качестве инструмента
                экосистемы для развития волонтерства и гражданской активности, находящейся
                по адресу добромол56.рф в сети Интернет.
            </p>
            <p>
                2.2. ЕИС ДОБРОМОЛ56.РФ самостоятельно или посредством дополнительных
                инструментов осуществляет сбор информации, передачу таких данных о
                Пользователе лицам, зарегистрированных на ЕИС ДОБРОМОЛ56.РФ,
                региональным представителям и иным третьим лицам, в целях:
            </p>
            <p>
                2.2.1. поиска и доступа к сведениям о добровольческой (волонтерской)
                деятельности, включающих информацию о количестве часов, затраченных на
                добровольческую (волонтерскую) деятельность, компетенциях добровольцев
                (волонтеров) и полученном ими опыте;
            </p>
            <p>
                2.2.2. управления добровольцами (волонтерами) в процессе проведения
                мероприятия и их оценке по окончанию мероприятия;
            </p>
            <p>
                2.2.3. создания унифицированной системы учета добровольческой (волонтерской)
                деятельности;
            </p>
            <p>
                2.2.4. поиска добровольцев (волонтеров) и их привлечения к деятельности общественных и государственных организаций, инициативных групп граждан, а также организаторов добровольческой (волонтерской) деятельности;
            </p>
            <p>
                2.2.5. анализа развития добровольческой (волонтерской) деятельности на территории Оренбургской области и формирование на его основе аналитической и статистической информации;
            </p>
            <p>
                2.2.6 сбора и систематизации информации о добровольческой (волонтерской) деятельности.
            </p>
            <p>
                2.3. ЕИС ДОБРОМОЛ56.РФ  предоставляет Пользователю возможность единого,
                безопасного управления данными аккаунта.
            </p>
            <p>
                2.4. Использование ЕИС ДОБРОМОЛ56.РФ , помимо настоящего Соглашения,
                требует принятия и/или соблюдения отдельных официальных документов
                <strong>Ресурсного центра</strong>, которые являются неотъемлемой частью настоящего
                Соглашения, а именно:
            </p>
            <p>
                2.4.1. согласие с Правилами пользования
                (<a href="https://добромол56.рф/ckfinder/userfiles/files/Правила%20пользования.pdf">https://добромол56.рф/ckfinder/userfiles/files/Правила%20пользования.pdf</a>);
            </p>
            <p>
                2.4.2. согласие с Публичной политикой
                (<a href="https://добромол56.рф/ckfinder/userfiles/files/Публичная%20политика.pdf">https://добромол56.рф/ckfinder/userfiles/files/Публичная%20политика.pdf</a>);
            </p>
            <p>
                2.4.3. согласие на обработку персональных данных
                (<a href="https://добромол56.рф/ckfinder/userfiles/files/Согласие%20Пользователя%20на%2
                    0обработку%20персональных%20данных.pdf">
                    https://добромол56.рф/ckfinder/userfiles/files/Согласие%20Пользователя%20на%2
                    0обработку%20персональных%20данных.pdf
                </a>).
            </p>
            <p>
                2.5. Создавая аккаунт на ЕИС ДОБРОМОЛ56.РФ , Пользователь соглашается с
                тем, что ознакомился и принял все условия официальных документов <strong>Ресурсного центра</strong>.
            </p>
            <Text.SubHeader>
                3. Создание аккаунта на ЕИС ДОБРОМОЛ56.РФ
            </Text.SubHeader>
            <p>
                3.1. Создание аккаунта на ЕИС ДОБРОМОЛ56.РФ  является бесплатным и
                осуществляется с добровольного согласия Пользователя, путем заполнения
                регистрационной формы в сети Интернет по адресу : домбромол56.рф
            </p>
            <p>
                3.2. Создание аккаунта на ЕИС ДОБРО РФ. физическим лицом, достигшим
                возраста 14 лет, доступно, путем самостоятельной регистрации, с учетом
                требований настоящего Пользовательского соглашения и иных обязательных
                документов ЕИС ДОБРОМОЛ56.РФ .
            </p>
            <p>
                3.3. Создание аккаунта физическим лицом в возрасте от 8 до 14 лет возможно в
                следующих случаях:
            </p>
            <p>
                3.3.1. Создание аккаунта с добровольного согласия законных представителей.
                При этом законные представители заполняют все необходимые данные,
                необходимые для создания аккаунта, включая предоставление данных документа,
                подтверждающего полномочия законного представителя (свидетельство о
                рождении или иное). Согласие на обработку персональных данных
                несовершеннолетнего физического лица в возрасте от 8 до 14 лет дается от
                имени и с согласия законного представителя.
            </p>
            <p>
                3.3.2. Самостоятельное создание аккаунта, без согласия законных
                представителей возможно, исключительно с целью получения рассылок с
                информацией о самых интересных событиях в сфере добровольчества,
                осуществляется без предоставления и обработки персональных данных
                физического лица и без предоставления доступа ко всем инструментам ЕИС
                ДОБРОМОЛ56.РФ.
            </p>
            <p>
                3.4. Создание аккаунта физическими лицами, не достигшими возраста 8 лет, не
                допускается.
            </p>
            <p>
                3.5. В случае самостоятельной регистрации, за исключением п. 3.3.2., в процессе
                заполнения регистрационной формы ЕИС ДОБРОМОЛ56.РФ  в соответствии с
                п.3.1 на сайте в сети Интернет по адресу dobro.ru. Пользователь указывает
                обязательные, актуальные, достоверные данные, необходимые для регистрации
                на ЕИС ДОБРОМОЛ56.РФ .
            </p>
            <p>
                3.6. Соглашаясь с условиями настоящего Соглашения, Пользователь
                подтверждает свое право- и дееспособность. Пользователь подтверждает и несет
                ответственность за достоверность, актуальность, полноту и соответствие
                законодательству Российской Федерации информации, предоставленной при
                создании аккаунта и ее чистоту от претензий третьих лиц.
            </p>
            <p>
                3.7. <strong>Ресурсный центр</strong> не проверяет достоверность предоставленной
                Пользователями информации, за исключением случаев, когда такая проверка
                необходима в целях исполнения <strong>Ресурсным центром</strong> обязательств перед
                Пользователями ЕИС ДОБРОМОЛ56.РФ
            </p>
            <p>
                3.8. В случае самостоятельной регистрации после предоставления необходимой
                для регистрации информации Пользователю необходимо пройти ряд
                удостоверительных процедур, а именно подтвердить регистрацию путем перехода
                по ссылки из сообщения электронной почты, либо введения в соответствующую
                форму на сайте в сети Интернет кода, полученного Пользователем в сообщении
                электронной почты, либо в виде sms-сообщения/звонка-сброса/звонка от робота
                или push-уведомления от Ассоциации на мобильный телефон Пользователя,
                номер которого предоставляется самим Пользователем, либо другим способом,
                описание которого предоставляется Пользователю до регистрации. В случае
                верного последовательного выполнения всех регистрационных действий
                создается аккаунт на ЕИС ДОБРОМОЛ56.РФ .
            </p>
            <p>
                3.9. После успешного создания аккаунта Пользователь и <strong>Ресурсный центр</strong>
                принимают на себя права и обязанности, указанные в официальных документах
                ЕИС ДОБРОМОЛ56.РФ .
            </p>
            <p>
                3.10. Обработка персональных данных Пользователя ЕИС ДОБРОМОЛ56.РФ
                осуществляется в соответствии с законодательством Российской Федерации.
                Ассоциация принимает все необходимые меры для защиты персональных данных
                Пользователя от неправомерного доступа, изменения, раскрытия или
                уничтожения. <strong>Ресурсный центр</strong> предоставляет доступ к персональным данным
                Пользователя работникам, подрядчикам, региональным представителям и иным
                третьим лицам, с целью, указанных в п. 2.2. настоящего Соглашения.
            </p>
            <p>
                3.11. <strong>Ресурсный центр</strong> вправе использовать предоставленную Пользователем
                ЕИС ДОБРОМОЛ56.РФ  информацию, в том числе персональные данные, а также
                передавать ее третьим лицам, в целях обеспечения соблюдения требований
                действующего законодательства Российской Федерации, защиты прав и
                интересов Пользователей ЕИС ДОБРОМОЛ56.РФ . Раскрытие предоставленной
                Пользователем ЕИС ДОБРОМОЛ56.РФ  информации может быть произведено
                лишь в соответствии с действующим законодательством Российской Федерации
                по требованию суда, правоохранительных органов, а равно в иных
                предусмотренных законодательством Российской Федерации случаях.
            </p>
            <p>
                3.12. Пользователь ЕИС ДОБРОМОЛ56.РФ  не имеет права предоставлять доступ
                к своему аккаунту иным лицам, несет полную ответственность за их сохранность
                данных, необходимых для авторизации, самостоятельно выбирая способ их
                хранения.
            </p>
            <p>
                3.13. Если Пользователем не доказано обратное, любые действия, совершенные
                посредством его аккаунта на ЕИС ДОБРОМОЛ56.РФ , считаются совершенными
                соответствующим Пользователем. В случае несанкционированного доступа к
                аккаунту Пользователь обязан незамедлительно сообщить об этом <strong>Ресурсному центру</strong>, направив сообщение по электронной почте на адрес <a href="mailto:orrc56@mail.ru">orrc56@mail.ru</a>
            </p>

            <Text.SubHeader>4. Права и обязанности Пользователя:</Text.SubHeader>
            <p>4.1. При использовании ЕИС ДОБРОМОЛ56.РФ  Пользователь обязан:</p>
            <p>
                4.1.1. соблюдать положения действующего законодательства Российской
                Федерации, настоящего Соглашения и иных официальных документов
                <strong>Ресурсного центра</strong>;
            </p>
            <p>
                4.1.2. предоставлять при создании аккаунта достоверные, полные и актуальные
                данные, следить за их актуализацией;
            </p>
            <p>
                4.1.3. информировать <strong>Ресурсный центр</strong> о несанкционированном доступе к
                аккаунту на ЕИС ДОБРОМОЛ56.РФ ;
            </p>
            <p>
                4.1.4. не предоставлять доступ другим лицам к своему аккаунту или к отдельной
                содержащейся в нем информации в случае, если это может привести к
                нарушению законодательства Российской Федерации и/или настоящего
                Соглашения;
            </p>
            <p>
                4.1.5. Не распространять, полученную и/или собранную с использованием ЕИС
                ДОБРОМОЛ56.РФ  любую конфиденциальную, персональную и охраняемую
                законодательством Российской Федерации информацию о физических лицах.
            </p>
            <p>
                4.1.6. Информация, комментарии и иные записи Пользователя на ЕИС
                ДОБРОМОЛ56.РФ  не должны вступать в противоречие с требованиями
                законодательства Российской Федерации, а также не должны негативно влиять на
                имидж ЕИС ДОБРОМОЛ56.РФ .
            </p>
            <p>4.2. Пользователю ЕИС ДОБРОМОЛ56.РФ  запрещается:</p>
            <p>
                4.2.1. создавать аккаунт в качестве Пользователя от имени или вместо другого
                лица («фальшивый аккаунт»), кроме случая создания аккаунта родителями,
                опекунами или лицами, их заменяющими, для несовершеннолетнего
                пользователя в возрасте от 8 до 14 лет;
            </p>
            <p>4.2.2. предоставлять некорректные, искаженные данные;</p>
            <p>
                4.2.3. использовать программное обеспечение и осуществлять действия,
                направленные на нарушение нормального функционирования ЕИС
                ДОБРОМОЛ56.РФ ;
            </p>
            <p>
                4.2.4. любым способом, в том числе, но не ограничиваясь, путем обмана,
                злоупотребления доверием, взлома, пытаться получить доступ к аккаунту другого
                Пользователя.
            </p>
            <p>4.2.5. использовать возможности ЕИС ДОБРОМОЛ56.РФ  с целью:</p>
            <p>
                4.2.5.1. загрузки контента, который пропагандирует насилие, жестокость,
                ненависть и (или) дискриминацию по расовому, национальному, половому,
                религиозному, социальному признакам; содержит недостоверные сведения и
                (или) оскорбления в адрес конкретных лиц, организаций, органов власти;
            </p>
            <p>
                4.2.5.2. побуждения к совершению противоправных действий, а также содействия
                лицам, действия которых направлены на нарушение ограничений и запретов,
                действующих на территории Российской Федерации;
            </p>
            <p>
                4.2.5.3. загрузки, хранения, публикации, распространения и предоставления
                доступа или иным образом использовать вирусы, трояны и другие вредоносные
                программы.
            </p>
            <p>4.3. Пользователь ЕИС ДОБРОМОЛ56.РФ  имеет право:</p>
            <p>
                4.3.1. В случае несогласия Пользователя с настоящим Соглашением, его
                изменениями и дополнениями, Пользователь обязан отказаться от использования
                ЕИС ДОБРОМОЛ56.РФ , проинформировав об этом <strong>Ресурсный центр</strong>.
            </p>
            <Text.SubHeader>5. Права и обязанности Ресурсного центра:</Text.SubHeader>
            <p>
                5.1. Ассоциация осуществляет текущее управление и развитие ЕИС
                ДОБРОМОЛ56.РФ , разрешает или ограничивает доступ Пользователей к ЕИС
                ДОБРОМОЛ56.РФ  при нарушении такими лицами положений настоящего
                Соглашения и иных официальных документов, а также осуществляет иные
                принадлежащие ей права.
            </p>
            <p>5.2. Ассоциация вправе:</p>
            <p>
                5.2.1. оказывать в разумные сроки техническую и информационную поддержку
                Пользователя, необходимую для работы на ЕИС ДОБРОМОЛ56.РФ ;
            </p>
            <p>
                5.2.2. осуществлять прямые контакты с Пользователем ЕИС ДОБРОМОЛ56.РФ  с
                помощью средств связи (по телефону, по электронной почте или иными
                способами, в целях получения от Пользователя информации/комментариев о
                возникших проблемах/вопросах при использовании ЕИС ДОБРОМОЛ56.РФ  или
                получения обратной связи о функционировании ЕИС ДОБРОМОЛ56.РФ , с целью
                дальнейшего развития/совершенствования ЕИС ДОБРОМОЛ56.РФ ;
            </p>
            <p>
                5.2.3. приостанавливать доступ к ЕИС ДОБРОМОЛ56.РФ  без предварительного
                уведомления Пользователей в случае необходимости проведения
                профилактических работ, в случае наступления форс-мажорных обстоятельств, а
                также аварий или сбоев в программно-аппаратных комплексах третьих лиц, или
                действий третьих лиц, направленных на приостановку или прекращение
                функционирования ЕИС ДОБРОМОЛ56.РФ ;
            </p>
            <p>
                5.3. При исполнении настоящего соглашения <strong>Ресурсный центр</strong> в своей
                деятельности руководствуется требованиями применимого законодательства, в
                том числе Федерального закона Российской Федерации от 27 июля 2006 года №
                149-ФЗ «Об информации, информационных технологиях и о защите
                информации».
            </p>
            <Text.SubHeader>
                6. Прекращение действия аккаунта Пользователя на ЕИС
                ДОБРОМОЛ56.РФ .
            </Text.SubHeader>
            <p>
                6.1. Ассоциация не несет ответственности за нарушение Пользователем ЕИС
                ДОБРОМОЛ56.РФ  настоящего Соглашения и оставляет за собой право по своему
                собственному усмотрению, а также при получении информации от других
                пользователей либо третьих лиц о нарушении Пользователем настоящего
                Соглашения заблокировать/удалить аккаунт Пользователя и/или приостановить,
                ограничить или прекратить доступ Пользователя ЕИС ДОБРОМОЛ56.РФ  в любое
                время по любой причине или без объяснения причин, с предварительным
                уведомлением или без такового, без возмещения каких-либо затрат или убытков
                заблокировать/удалить аккаунт Пользователя.
                Однако <strong>Ресурсный центр</strong> приложит все возможные усилия для исключения
                подобных ситуаций.
            </p>
            <Text.SubHeader>7. Гарантии и ответственность</Text.SubHeader>
            <p>
                7.1. Пользователи ЕИС ДОБРОМОЛ56.РФ  несут ответственность перед
                <strong>Ресурсным центром</strong> за соблюдение условий настоящего Соглашения.
            </p>
            <p>
                7.2. <strong>Ресурсный центр</strong> не принимает на себя никаких обязательств и не несет
                никакой ответственности за какие-либо прямые или косвенные последствия
                какого-либо использования или невозможности использования ЕИС
                ДОБРОМОЛ56.РФ , в том числе, в результате утраты Пользователем доступа к
                аккаунту ЕИС ДОБРОМОЛ56.РФ , и/или ущерб, причиненный Пользователю ЕИС
                ДОБРОМОЛ56.РФ  и/или третьим сторонам в результате какого-либо
                использования, неиспользования или невозможности использования ЕИС
                ДОБРОМОЛ56.РФ , в том числе из-за временной или постоянной утраты доступа к
                аккаунту по любой причине, возможных ошибок или сбоев в работе ЕИС
                ДОБРОМОЛ56.РФ .
            </p>
            <p>
                7.3. Ассоциация обеспечивает функционирование и работоспособность ЕИС
                ДОБРОМОЛ56.РФ  и обязуется оперативно восстанавливать его
                работоспособность в случае технических сбоев и перерывов. Ассоциация не
                несет ответственности за временные сбои и перерывы в работе ЕИС
                ДОБРОМОЛ56.РФ  и вызванные ими потерю информации.
            </p>
            <p>
                7.4. Никакие положения настоящего Соглашения не предоставляют Пользователю
                право на использование фирменного наименования, товарных знаков, доменных
                имен и иных отличительных знаков <strong>Ресурсного центра</strong>.
            </p>
            <p>
                7.5. <strong>Ресурсный центр</strong> имеет право распоряжаться статистической информацией,
                связанной с функционированием ЕИС ДОБРОМОЛ56.РФ .
            </p>
            <p>
                7.6. <strong>Ресурсный центр</strong> имеет право направлять Пользователю информацию о
                развитии ЕИС ДОБРОМОЛ56.РФ , ее функциональности, а также рекламировать
                собственную деятельность. Пользователь ЕИС ДОБРОМОЛ56.РФ  соглашается
                на получение электронных сообщений, sms и иных видов рассылок
                информационного, в т.ч. рекламно-информационного, содержания.
            </p>
            <Text.SubHeader>8. Заключительные положения</Text.SubHeader>
            <p>
                8.1. Признание какого-либо положения настоящего Соглашения
                недействительным, или не подлежащим принудительному исполнению, не влечет
                недействительности или неисполнимости иных положений настоящего
                Соглашения.
            </p>
            <p>
                8.2. Настоящее Соглашение регулируется и толкуется в соответствии с
                законодательством Российской Федерации. Вопросы, не урегулированные
                настоящим Соглашением, подлежат разрешению в соответствии с
                законодательством Российской Федерации.
            </p>
            <p>
                8.3. Все споры сторон по настоящему Соглашению подлежат разрешению путем
                переписки и переговоров с использованием обязательного досудебного
                (претензионного) порядка.
            </p>
            <p>
                8.4. Обращения, предложения и претензии физических и юридических лиц к
                <strong>Ресурсному центру</strong> в связи с настоящим Соглашением и всеми вопросами по
                функционированию ЕИС ДОБРОМОЛ56.РФ , нарушениями прав и интересов
                третьих лиц при его использовании, а также для запросов уполномоченных
                законодательством Российской Федерации лиц могут быть направлены по адресу
                местонахождения <strong>Ресурсного центра</strong>.
            </p>
            {
                !isTablet &&
                <Redirect
                    header="Далее"
                    to={`/${RouteEnum.PRIVACY}`}
                    callback={smoothScroll}
                >
                    <Text.Header>
                        Политика обработки персональных данных
                    </Text.Header>
                </Redirect>
            }
        </PrivacyLayout>
    )
}

export default PrivacyUserAgreementPage
