import { type FC } from "react"
import "./index.scss"
import { type IProgress } from "./interface"

const Progress: FC<IProgress> = (props) => {
    const { maxPercentage = 100, value = 0, itemsCount = 0, maxItemsCount = 1, ...othersProps } = props

    return (
        <div className="progress">
            <progress
                className="progress__progress"
                max={maxPercentage}
                value={value}
                {...othersProps}
            />
            <div className="progress__values">
                <p><span className="progress__bold">{itemsCount}</span> / {maxItemsCount}</p>
                <p className="progress__bold">{value}%</p>
            </div>
        </div>
    )
}

export default Progress
