import { type FC } from "react"
import "./index.scss"
import { ArrowForwardIcon, Button, Card, LogoutIcon } from "../../../../components"
import { dobroLogoSvg } from "../../../../assets/svg"
import { useNavigate } from "react-router-dom"
import { type ProfileHeaderI } from "./interface"
import useMediaQuery from "presentation/hooks/useMediaQuery"
import dayjs from "dayjs"
import { DOBRO_URL } from "presentation/helper"
import { useGetProfileDataQuery } from "presentation/store/services/profileApi"

const ProfileHeader: FC<ProfileHeaderI> = ({ userData, }) => {
    const navigation = useNavigate()

    const { isTablet, } = useMediaQuery()

    const handleLogoutClick = (): void => {
        localStorage.clear()
        navigation("/login")
    }

    const {
        fullname,
        birthday,
        city,
        volunteerId,
    } = userData

    const handleRedirectToDobroProfile = (): void => {
        window.location.href = DOBRO_URL + "/dashboard/requests"
    }

    const { data, } = useGetProfileDataQuery("")
    const avatar = data?.user.imageUrl

    return (
        <section className="profile-header">
            <div className="profile-header__left">
                <img
                    className="profile-header__avatar"
                    src={avatar || undefined}
                    alt="avatar"
                />
                <Button
                    onClick={handleLogoutClick}
                    className="profile-header__exit"
                    rigthIcon={<LogoutIcon />}
                    theme="gray"
                >
                    { !isTablet && "Выйти из аккаунта"}
                </Button>
            </div>
            <div>
                <h1 className="profile-header__fullname">
                    {
                        fullname
                    }
                </h1>
                <div className="profile-header__characteristic">
                    <Card className="profile-header__date">
                        <p className="profile-header__title profile-header__age">
                            {
                                dayjs().diff(birthday, "year")
                            }
                        </p>
                        <p className="profile-header__sub-title">
                            День рождения
                        </p>
                        <p className="profile-header__birthdate">
                            {
                                birthday && dayjs(birthday).format("DD.MM.YYYY")
                            }
                        </p>
                    </Card>
                    <Card className="profile-header__location">
                        <p className="profile-header__sub-title">
                            Проживает
                        </p>
                        <p className="profile-header__title">
                            {
                                city
                            }
                        </p>
                    </Card>
                    <Card className="profile-header__bottom">
                        <div className="profile-header__information">
                            <img
                                className="profile-header__logo"
                                src={dobroLogoSvg}
                                alt="dobro-ru"
                            />
                            <div className="profile-header__info-left">
                                <p className="profile-header__sub-title">
                                    ID на сайте dobro.ru
                                </p>
                                <p className="profile-header__title">
                                    {
                                        volunteerId
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="profile-header__button-container">
                            <Button
                                rigthIcon={<ArrowForwardIcon/>}
                                className="profile-header__button-next"
                                size={!isTablet ? "small" : undefined}
                                theme="gray"
                                onClick={handleRedirectToDobroProfile}
                            >
                                Перейти
                            </Button>
                        </div>
                    </Card>
                </div>
            </div>
        </section>
    )
}

export default ProfileHeader
