import React, { type FC } from "react"
import { type LiProps } from "./interface"

export const Li: FC<LiProps> = (props) => {
    const { children, } = props
    return (
        <li className={"List__item"}>
            {children}
        </li>
    )
}
