import { type FC } from "react"
import { AuthLayout } from "../../layouts"
import { LoginForm } from "../../form"

const SignInPage: FC = () => {

    return (
        <AuthLayout>
            <LoginForm />
        </AuthLayout>
    )

}

export default SignInPage
