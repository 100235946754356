import { type FC } from "react"
import "./index.scss"
import { type INotificationItem } from "./interface"
import dayjs from "dayjs"

const NotificationItem: FC<INotificationItem> = (props) => {
    const { title, message, timestamp, viewed, icon, } = props

    const currentClasses = ["notification-item"]

    if (viewed) {
        currentClasses.push("notification-item_viewed")
    }

    return (
        <div className={currentClasses.join(" ")}>
            <div className="notification-item__icon">
                {icon}
            </div>
            <div className="notification-item__content">
                <p className="notification-item__title"><span>{title}</span></p>
                <p
                    className="notification-item__decs"
                    dangerouslySetInnerHTML={{ __html: message, }}>
                </p>
                <p className="notification-item__date-time">
                    {dayjs(timestamp).format("DD.MM.YYYY HH:mm")}
                </p>
            </div>
        </div>
    )
}

export default NotificationItem
