import { type FC } from "react"
import Router from "./main/routes/router"
import { Provider } from "react-redux"
import { store } from "presentation/store"

const App: FC = () => {
    return (
        <Provider store={store}>
            <Router/>
        </Provider>
    )
}

export default App
